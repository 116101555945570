<template>
  <div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in data"
              v-bind:class="[background(tr)]"
              
            >
              <!-- <vs-td :data="data[indextr].ID">{{data[indextr].ID}}</vs-td> -->
              <vs-button
                v-if="dataId == tr.ID"
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >

              <vs-td>
                <vs-button
                  v-if="dataId != tr.ID"
                  v-on:click="clickTr(tr)"
                  color="primary"
                  type="gradient"
                  icon-pack="feather"
                  icon="icon-search"
                ></vs-button>
              </vs-td>
              <vs-td>
                {{ tr.Title }}
              </vs-td>
              <vs-td>
                <template v-for="(r,i) in JSON.parse(tr.Value )">
                  <div v-bind:key="i">
                    <template v-for="(sr,si) in r">
                      <div v-bind:key="i+si">
                        {{si}}:{{sr}}
                        <br/>
                      </div>
                    </template>
                    <hr>
                  </div>
                </template>
              </vs-td>
             
            </vs-tr>
          </template>
        </data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button>
            </vs-col>
            <div>
              <template v-for="(r,i) in valueOption">
                <div v-bind:key="i">
                  <template v-for="(sr,si) in r">
                    <div v-bind:key="i+si" class="vx-row mb-6">
                      <div class="vx-col w-full">
                        <vs-input
                          class="w-full"
                          :label="si"
                          v-validate="'required'"
                          name="Name"
                          v-model="valueOption[i][si]" />
                      </div>
                    </div>
                  </template>
                  <hr>
                </div>
              </template>
              <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// import detail from "./detail.vue";

export default {
  components: {
    // detail,
  },
  props: [
    "territory",
    "salesChannel",
    "customerCategory",
    "startDate",
    "endDate",
  ],
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "title",
      },
      header: [
        {
          text: "Action",
          // width: '5%'
          value: "title",
        },
        {
          text: "Title",
          // width: '5%'
          value: "title",
        },
        {
          text: "Value",
          value: "value",
        }
      ],
      responseData: {},
      isActive: 1,
      valueOption: [],
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      dataId: 0,
    };
  },
  watch: {},
  mounted() {
    this.reloadData(this.params);
  },
  methods: {
    clickTr(val) {
      this.closeDetail();
      setTimeout(function () {}, 3000);
      this.dataId = val.ID;
      this.selectedData = val;
      this.detail = true;
      this.valueOption = JSON.parse(val.Value)
      // this.closeDetail()
    },
    closeDetail() {
      this.dataId = 0;
      this.detail = false;
    },
    background(tr){
      if(this.dataId == tr.ID){
        return "colored"
      }else {
        const persen = parseFloat(tr.balance)/parseFloat(tr.amount)
        if(persen < 0){
          return "red"
        }else if (persen < 0.5){
          return "yellow"
        }
        return ""
      }
    },
    handleSubmit(){
      var valueOption = JSON.parse(JSON.stringify(this.valueOption))
      valueOption = valueOption.map(v => {
        if (this.selectedData.Title == "materai") {
          v.cost = parseInt(v.cost)
          v.min = parseInt(v.min)
          v.max = parseInt(v.max)
        } else {
          v.nominal = parseInt(v.nominal)
        }
        return v
      })
      console.log(JSON.stringify(valueOption))
      this.$vs.loading();
      this.$http
        .put(`api/v1/setting/finance/${this.dataId}`, {
          'id':this.dataId,
          'value':JSON.stringify(valueOption)
        })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.detail = false
            this.reloadData(this.params)
            // this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
          this.closeDetail()
        });
    },
    reloadData(params) {
      console.log(this.params.sort);
      this.params = params;
      console.log(params);
      this.$vs.loading();
      this.$http
        .get("api/v1/setting/finance", {
          params: {
            search: this.params.search,
            length: this.params.length,
            page: this.params.page,
            order: this.params.order,
            sort: this.params.sort,
          },
        })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.setting;
            this.responseData = resp.data;
            this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 50%;
  text-align: justify;
  word-wrap: break-word;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.red {
  background: red;
  background-color: red;
}
.yellow {
  background: yellow;
  background-color: yellow;
}
</style>